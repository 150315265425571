<script>
import ProfileItem from "../../profiles/ProfileItem.vue";
import Table from "../../profile/_Table.vue";

export default {
  data() {
    return {
      menu: 'account'
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    Table,
    ProfileItem,
  },
  methods: {
    selectAccountMenu(item) {
      this.menu = item;
      this.$emit('changedSidebarMenu', item);
    },
  }
};
</script>

<template>
  <div class="team-list grid-view-filter row">

    <div class="col-12">
      <ProfileItem v-bind:data="data.profile" v-if="data.profile"></ProfileItem>
    </div>

    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h5 class="mb-0">Welcome!</h5>
        </div>
        <b-list-group class="list-group-fill-primary list-group-flush">
          <b-list-group-item :active="menu === 'account'" v-on:click="selectAccountMenu('account')" class="list-group-item-action" role="button">
            <i class="ri-profile-line align-middle me-2"></i> Account
          </b-list-group-item>
          <b-list-group-item :active="menu === 'security'" v-on:click="selectAccountMenu('security')" class="list-group-item-action" role="button">
            <i class="ri-lock-2-line align-middle me-2"></i> Security
          </b-list-group-item>
          <b-list-group-item :active="menu === 'settings'" v-on:click="selectAccountMenu('settings')" class="list-group-item-action" role="button">
            <i class="ri-settings-3-line align-middle me-2"></i> Settings
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>

    <div class="col-12">
      <Table v-bind:data="data.profile"></Table>
    </div>

  </div>
</template>