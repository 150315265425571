<script>
export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
};
</script>

<template>
  <div class="card mb-3">
    <div class="card-body">
      <h6 class="text-muted text-uppercase fw-semibold mb-3"> Profile details </h6>
      <p class="text-muted mb-4" v-if="false"></p>
      <div class="table-responsive table-card">
        <table class="table table-borderless mb-0 sidebar-rows">
          <tbody>
            <tr class="fw-medium" scope="row" v-if="data.location">
              <td class="fw-medium">Location</td>
              <td>{{ data.location }}</td>
            </tr>
            <tr>
              <td class="fw-medium" scope="row">Joined</td>
              <td>{{ new Date(data.createdAt).toLocaleString() }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
